:root {
    --space-grey: linear-gradient(#878787, #a8a8a8,#878787);
    --white: #ffffff;
}

#default-example {
    background: linear-gradient(#212121,#2b2b2b);
    perspective: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.output section {
    display: flex;
    align-items: center;
    justify-content: center;
}
.output section {
    height: 100%;
    text-align: center;
}

#example-element {
    width: 320px;
    height: 180px;
    /*transform: translate3d(0, -50%, 0);*/
    transform-origin: 0 0;
    transform-style: preserve-3d;
}

.transition-all {
    transition: all 1s ease-in;
}

.face {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: inherit;
    font-size: 10px;
    color: #fff;

    border: 3px solid var(--space-grey);
    /*background-color: ${laptopColors.frame};*/
    border-radius: 10px;

    outline: none;
}

.front {
    width: 320px;
    height: 180px;
    background: var(--space-grey);
    transform: translateZ(5px);
}

.back {
    width: 320px;
    height: 180px;
    background: var(--space-grey);
    transform: rotateY(180deg) translateZ(5px);
}

.right {
    width: 10px;
    height: 180px;
    background: var(--space-grey);
    transform: rotateY(90deg) translateZ(315px);
}

.left {
    width: 10px;
    height: 180px;
    background: var(--space-grey);
    transform: rotateY(-90deg) translateZ(5px);
}

.top {
    width: 320px;
    height: 10px;
    background: var(--space-grey);
    transform: rotateX(90deg) translateZ(5px);
}

.bottom {
    width: 320px;
    height: 10px;
    background: var(--space-grey);
    transform: rotateX(-90deg) translateZ(175px);
}

.circle {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background: white;
}

.point-light {
    width: 1px;
    height: 1px;
    border-radius: 50%;
    background-color: transparent;
    box-shadow:
            0 0 60px 30px #fff,  /* inner white */
            0 0 100px 60px #a4a8a5, /* middle magenta */
            0 0 180px 120px #eeeeee; /* outer cyan */
}
