.WordControllerPage__formInputContainer {
    text-align: initial;
}

.WordControllerPage__normalContainer {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    margin-left: 20px;
    padding: 16px;
    position: relative;
    margin-top: 27px;
}

.WordControllerPage__title {
    border: 1px solid black;
    padding: 4px;
    font-size: 14px;
    position: absolute;
    top: -27px;
    left: 10px;
}

.WordControllerPage__container {
    background-color: rgba(153,227,145,0.4);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 20px;
    padding: 16px;
    position: relative;
}

.WordControllerPage__inputLabel {
    margin-bottom: 10px;

}

.WordControllerPage__input {
    height: 40px;
    font-family: Helvetica;
    font-size: 22px;
    line-height: 24px;
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid #eaeaea;
    padding: 8px;
    padding-left: 16px;
    max-width: 100%;
}

.WordControllerPage__inputTextArea {
    font-family: Helvetica;
    font-size: 22px;
    line-height: 24px;
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid #eaeaea;
    padding: 8px;
    padding-left: 16px;
    width: 245px !important;
    height: 250px;
    max-width: 100%;
}

.WordControllerPage__card {
    border-radius: 5px;
    border: 1px solid gray;
    margin: 20px;
    display: flex;
    flex-direction: row;
    padding: 20px;
    flex-wrap: wrap;
}
